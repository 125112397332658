




















import { Component, Vue } from 'vue-property-decorator';
import VueRouter from 'vue-router';
import JuejinLogo from '../assets/icon-juejin.svg';

@Component({
  components: {
    JuejinLogo,
  },
})
export default class WebsiteNav extends Vue {
  private sites: object[] = [
    {
      icon: 'weixin',
      href: '',
      onclick: (e: Event, router: VueRouter) => {
        e.preventDefault();
        e.stopPropagation();
        router.push({ name: 'weixin' });
      },
    },
    {
      icon: 'github',
      href: 'https://github.com/noahziheng',
    },
    {
      icon: 'juejin',
      href: 'https://juejin.im/user/583bdbf1a22b9d006c1514f8',
    },
    // {
    //   icon_prefix: 'fas',
    //   icon: 'archive',
    //   href: 'https://blog.noahgao.net',
    // },
    {
      icon: 'weibo',
      href: 'https://weibo.com/noah1719',
    },
    {
      icon: 'zhihu',
      href: 'https://www.zhihu.com/people/noah-gao/activities',
    },
  ];
}
