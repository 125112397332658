






















import { Component, Vue } from 'vue-property-decorator';
import WebsiteNav from '@/components/WebsiteNav.vue';

@Component({
  components: {
    WebsiteNav,
  },
})
export default class Home extends Vue {}
