














import { Component, Vue } from 'vue-property-decorator';
import LangSelect from '@/components/LangSelect.vue';
import PageFooter from '@/components/PageFooter.vue';

import 'normalize.css';

@Component({
  components: {
    LangSelect,
    PageFooter,
  },
})
export default class App extends Vue {
  get fullscreenFlag(): boolean {
    const disableFullscreenRouteNameArr = ['about'];
    return !disableFullscreenRouteNameArr.find((val) => val === this.$route.name);
  }
}
