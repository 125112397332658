













import { Component, Vue } from 'vue-property-decorator';

@Component
export default class HelloWorld extends Vue {
  private handleSelect(val: string): void {
    this.$i18n.locale = val;
  }
}
